import { ApiGatewayPathBuilder, Env, IPlatformAdminEnvironment } from 'shared-lib'

const env: Env = 'production'
const pathBuilder = new ApiGatewayPathBuilder(env)

export const environment: IPlatformAdminEnvironment = {
  production: true,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyDCjJ68iZtelmCX9eh6FVyboIQ4O8xwEK8',
    authDomain: 'cnect-prod-100.firebaseapp.com',
    projectId: 'cnect-prod-100',
    storageBucket: 'cnect-prod-100.appspot.com',
    messagingSenderId: '918489190359',
    appId: '1:918489190359:web:324c7c53ff208b677a8592',
    databaseURL: 'https://cnect-prod-100.firebaseio.com',
    measurementId: 'G-8GSZB39LCK',
  },

  services: {
    jobs: pathBuilder.build('jobs'),
    registration: pathBuilder.build('registration'),
    user: pathBuilder.build('user'),
    auth: pathBuilder.build('auth'),
    files: pathBuilder.build('files'),
    customer: pathBuilder.build('customer'),
    notifications: pathBuilder.build('notifications'),
  },

  // We can turn authentication off in case we are using this application inside an SDK and do not want to have any authentication
  authentication: false,
  themesEnabled: true,
  v2ReCaptcha: '6LeaW0ocAAAAAAElSsVOmLLXYtSe6qlC8N8ZuXkt',
  tenantId: 'PLATFORM-USER-6vl37',
  algoliaAppId: '8KSBN2S7IA',
  cdnCname: 'content.cnected.com',
  version: '5226718',
  basePlatformHost: 'admin.cnect.jobs',
}
